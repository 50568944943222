<template>
    <div class="v-datepicker">

        <div class="v-calendar-display" @click="!disabled ? openDatepicker($el) : false">
            <div class="v-calendar-display__input">
                <label v-if="input" class="v-datepicker-input">{{input}}</label>
                <label v-if="!input && placeholder" class="v-datepicker-input placeholder">{{placeholder}}</label>
            </div>
            <div class="v-calendar-display__controls">
                <div v-if="clearable && input" class="control-icon clear-icon" @click="clear()">
                    <slot name="clear-icon">
                        <img src="./assets/clear.svg"/>
                    </slot>
                </div>
                <div class="control-icon calendar-icon">
                    <slot name="calendar-icon">
                        <img src="./assets/calendar.svg"/>
                    </slot>
                </div>
            </div>
        </div>

        <transition name="datepicker" mode="out-in">
        <div v-if="days.length === 7 && months.length === 12 && open" :datepicker-selector="rand" class="v-calendar">

            <div class="v-calendar__header">
                <div class="v-calendar-control">
                    <div class="v-calendar-control__button" @click="prev()">
                        <slot name="prev-icon">
                            <img src="./assets/back.svg"/>
                        </slot>
                    </div>
                    <div class="v-calendar-control__display">
                        {{months[month]}} {{year}}
                    </div>
                    <div class="v-calendar-control__button" @click="next()">
                        <slot name="next-icon">
                            <img src="./assets/forward.svg"/>
                        </slot>
                    </div>
                </div>
            </div>

            <div class="v-calendar__body">
                <div class="v-calendar-table">
                    <div class="v-calendar-table__header">
                        <div v-for="(day, index) in days" :key="index" class="v-calendar-day">
                            {{day.slice(0, 3)}}
                        </div>
                    </div>
                    <div class="v-calendar-table__body">
                        <div v-for="(row, index) in rows" :key="index" class="v-calendar-table__row">
                            <div v-for="(day, index) in row" :key="index"
                            class="v-calendar-day"
                            :class="{
                                'v-calendar-day--today': checkToday(day),
                                'v-calendar-day--selected': checkSelected(day),
                                'v-calendar-day--empty': !day,
                                'v-calendar-day--disabled': checkDisabledBefore(day) || checkDisabledAfter(day),
                            }"
                            @click="selectDate(day)">
                                {{day}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </transition>
    </div>
</template>

<script>

/* eslint-disable */ 

import dateFormat from 'dateformat';

export default {

    name: 'datepicker',
    props: {

        value: {
            type: String,
            default: '',
        },

        displayFormat: {
            type: String,
            default: 'dd.mm.yyyy',
        },

        outputFormat:{
            type: String,
            default: '',
        },

        days: {
            type: Array,
            default: () => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        },

        months: {
            type: Array,
            default: () => ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        disableBefore: {
            type: String,
            default: '',
        },

        disableAfter: {
            type: String,
            default: '',
        },

        clearable: {
            type: Boolean,
            default: true,
        },

        placeholder: {
            type: String,
            default: '',
        },

    },
    data(){

        return {

            rand: null,
            open: false,
            dropdownDOM: null,

            now: null,
            day: null,
            month: null,
            year: null,
            firstMonthDay: null,

            input: '',
            selected: {},
            disBefore: {},
            disAfter: {},

            rows: [],

        };
    },
    watch: {

        value(){
            this.setValue();
        },

        open(){

            if (this.open || !this.value) return;

            const date = this.formatToDefault(this.value);

            if (!date.default){
                console.warn('Datepicker: input value is invalid');
                return;
            }

            this.setView(date);
        },

        disableBefore(){
            this.setDisableBefore();
        },

        disableAfter(){
            this.setDisableAfter();
        },

    },
    methods: {

        setView(date){

            this.selected = date;
            this.month = date.month;
            this.year = date.year;

        },

        init(){

            this.rand = this.generateRand(10);
            this.now = new Date();
            this.day = this.now.getDate();
            this.month = this.now.getMonth();
            this.year = this.now.getFullYear();

            this.setValue();

        },

        setValue(){

            if (!this.value){
                this.input = '';
                return;
            }

            const date = this.formatToDefault(this.value);

            if (!date.default){
                console.warn('Datepicker: input value is invalid');
                return;
            }

            this.input = dateFormat(date.default, this.displayFormat);

            this.setView(date);

        },

        drawMonths(){

            this.rows = [];

            const days = new Date(this.year, this.month + 1, 0).getDate();
            const tempDate = new Date(this.year, this.month, 1);
            this.firstMonthDay = tempDate.getDay();
            let cells = 0;

            if (this.firstMonthDay === 0)  this.firstMonthDay = 7;

            cells = days + this.firstMonthDay - 1;

            if (this.firstMonthDay - 1 !== 0) this.rows.push([]);

            for (let i = 0; i < this.firstMonthDay - 1; i += 1) this.rows[this.rows.length - 1].push('');

            for (let i = this.firstMonthDay - 1; i < cells; i += 1) {

                if (i % 7 === 0) this.rows.push([]);

                this.rows[this.rows.length - 1].push(i - this.firstMonthDay + 2);

            }

        },

        checkToday(day){

            if (this.year === this.now.getFullYear()
            && this.month === this.now.getMonth()
            && this.day === day) return true;

            return false;

        },

        checkSelected(day){

            if (this.year === this.selected.year
            && this.month === this.selected.month
            && this.selected.day === day) return true;

            return false;

        },

        formatToDefault(date){
            
            const newDate = new Date(date);

            return {
                default: newDate, 
                day: newDate.getDate(), 
                month: newDate.getMonth(), 
                year: newDate.getFullYear(),
            };

        },

        selectDate(day){
            
            this.selected = {
                day,
                month: this.month,
                year: this.year,
            };

            const date = new Date(`${this.year}-${this.month + 1}-${day}`);

            const input = dateFormat(date, this.displayFormat);
            this.input = input;
            this.$emit('input', dateFormat(date, this.outputFormat));
            this.close();

        },

        prev(){

            this.month -= 1;

            if (this.month < 0) {
                this.month = 11;
                this.year -= 1;
            }

            this.drawMonths();

        },

        next(){

            this.month += 1;

            if (this.month > 11) {
                this.month = 0;
                this.year += 1;
            }

            this.drawMonths();

        },

        setDisableBefore(){

            if (this.disableBefore){
            
                const date = this.formatToDefault(this.disableBefore);

                if (!date.default){
                    console.warn('Datepicker: disableBefore prop is invalid');
                    return;
                }

                this.disBefore = date;
                return;

            }

            this.disBefore = {};

        },

        setDisableAfter(){

            if (this.disableAfter){
            
                const date = this.formatToDefault(this.disableAfter);

                if (!date.default){
                    console.warn('Datepicker: disableAfter prop is invalid');
                    return;
                }

                this.disAfter = date;
                return;

            }

            this.disAfter = {};

        },

        checkDisabledBefore(day){

            if (!this.disableBefore) return false;

            if (this.year <= this.disBefore.year
            && this.month <= this.disBefore.month){ // this.month <= this.disBefore.month - 1
                
                if (this.month === this.disBefore.month && day < this.disBefore.day) return true;
                if (this.month < this.disBefore.month) return true;

            }

            if (this.year < this.disBefore.year) return true;

            return false;

        },

        checkDisabledAfter(day){

            if (!this.disableAfter) return false;

            if (this.year >= this.disAfter.year
            && this.month >= this.disAfter.month){
                
                if (this.month === this.disAfter.month && day >= this.disAfter.day + 1) return true;
                if (this.month > this.disAfter.month) return true;

            }

            if (this.year > this.disAfter.year) return true;

            return false;

        },

        generateRand(length){

            let result             = '';
            const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;

            for (let i = 0; i < length; i += 1) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            
            return result;

        },

        async openDatepicker(el){

            if (this.open) return;

            this.open = true;

            await this.$nextTick();

            this.dropdownDOM = document.querySelector(`[datepicker-selector="${this.rand}"]`);
            this.setPickerPossition(el);
            document.body.addEventListener('click', this.closeEvent, true);

        },

        setPickerPossition(el){

            if (!this.dropdownDOM) return;

            const verticalSpace = window.innerHeight - el.getBoundingClientRect().top + el?.offsetHeight;  
            const horizontalSpace = window.innerWidth - el.getBoundingClientRect().right + el?.offsetWidth;     
            const dropdownHeight = this.dropdownDOM?.offsetHeight + 50;
            const dropdownWidth = this.dropdownDOM?.offsetWidth;

            this.dropdownDOM.style.bottom = '';
            this.dropdownDOM.style.top = `${el?.offsetHeight}px`;
            this.dropdownDOM.style.left = '0px';
            this.dropdownDOM.style.right = '';
            
            if (dropdownHeight > verticalSpace || this.setTop){

                this.dropdownDOM.style.top = '';
                this.dropdownDOM.style.bottom = `${el?.offsetHeight}px`;

            }

            if (dropdownWidth > horizontalSpace || this.setRight){

                this.dropdownDOM.style.left = '';
                this.dropdownDOM.style.right = `${el?.offsetWidth - (el?.offsetWidth / 2)}px`;

            }

        },

        clear(){

            this.input = '';
            this.selected = {};
            this.$emit('input', '');

        },

        closeEvent(e){

            if (this.dropdownDOM){

                const isClickInside = this.dropdownDOM.contains(e.target);

                if (!isClickInside){

                    this.close();

                }

            }

        },

        close(){

            document.body.removeEventListener('click', this.closeEvent, true);
            this.dropdownDOM = null;
            this.open = false;

        }

    },
    mounted(){

        this.drawMonths();

    },
    created(){

        this.init();
        this.setDisableBefore();
        this.setDisableAfter();

    },
    
};

</script>

<style lang="scss" src="./datepicker.scss"/>
