<template>
    <div class="v-select" :class="{'v-select--opened': open, 'v-select--top': setToTop}">

        <div class="v-select__display" @click="openDropdown($el)">
            <div v-if="!showSearch" class="v-selected">
                <slot name="display" :item="display">
                    <span v-if="display">{{label && !multiselect ? display[label] : display}}</span>
                </slot>
                <span v-if="!display" class="v-placeholder">{{placeholder}}</span>
            </div>
            <input v-if="showSearch && open" type="text" ref="search" v-model="search" class="v-search" @input="$emit('live', search)"/>
        </div>

        <div class="v-select__controls">
            <div v-if="display && !bussy && clearable" class="control-icon clear-icon" @click="clear()">
                <slot name="clear-icon">
                    <img src="./assets/clear.svg" />
                </slot>
            </div>
            <div v-if="!bussy" class="control-icon arrow-icon" :class="{'active': open}" @click="openDropdown($el)">
                <slot name="arrow-icon">
                    &#x2023;
                </slot>
            </div>
            <div v-if="bussy" class="bussy-container">
                <slot name="loader">
                    <div class="v-select-loader">
                        <div class="v-select-loader__spinner"></div>
                    </div>
                </slot>
            </div>
        </div>

        <div v-if="open" :dropdown-selector="rand" class="v-select-dropdown">
            <div v-if="$slots.promotedTitle && promotedItems.length" class="section-title"><slot name="promotedTitle"></slot></div>
            <div v-for="(promotedItem, index) in promotedItems" :key="`promo-${index}`" class="dropdown-item promoted-item" @click="multiselect ? addMultiItem(promotedItem) : selectItem(promotedItem)">
                <div v-if="multiselect" class="dropdown-item__checkbox">
                    <div class="v-checkbox" :class="{'selected' : check(promotedItem)}"></div>
                </div>
                <div class="dropdown-item__label">
                    <slot name="promotedItem" :item="promotedItem">
                        {{label ? promotedItem[label] : promotedItem}}
                    </slot>
                </div>
            </div>
            <div v-if="$slots.itemsTitle && items.length" class="section-title"><slot name="itemsTitle"></slot></div>
            <div v-for="(item, index) in items" :key="index" class="dropdown-item" @click="multiselect ? addMultiItem(item) : selectItem(item)">
                <div v-if="multiselect" class="dropdown-item__checkbox">
                    <div class="v-checkbox" :class="{'selected' : check(item)}"></div>
                </div>
                <div class="dropdown-item__label">
                    <slot name="item" :item="item">
                        {{label ? item[label] : item}}
                    </slot>
                </div>
            </div>
            <div v-if="items.length === 0 && promotedItems.length === 0 && !hideEmpty" class="dropdown-item">
                <div class="dropdown-item__label">
                    <slot name="noItems">
                        No items
                    </slot>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

/* eslint-disable */ 

export default {
    
    name: 'vselect',
    props: {

        value: {
            default: '',
        },

        items: {
            type: Array,
            default: () => [],
        },

        promotedItems: {
            type: Array,
            default: () => [],
        },

        label: {
            type: String,
            default: '',
        },

        reduce: {
            type: String,
            default: '',
        },

        searchable: {
            type: Boolean,
            default: false,
        },

        placeholder: {
            type: String,
            default: '',
        },

        clearable: {
            type: Boolean,
            default: false,
        },

        multiselect: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        hideEmpty: {
            type: Boolean,
            default: false,
        },

    },
    watch: {

        value(){

            this.findModel();

        },

        loading(){
            this.bussy = this.loading;
        },

    },
    data(){

      return {

        display: '',
        rand: null,

        dropdownDOM: null,
        open: false,

        selectedItems: [],
        showSearch: false,
        search: '',

        bussy: false,
        setToTop: false,

      };

    },
    methods: {

        generateRand(length){

            let result             = '';
            const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;

            for (let i = 0; i < length; i += 1) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            
            return result;

        },

        findModel(){

            if (this.reduce){

                if (!this.multiselect){
                
                    const checkDisplay = this.items.find((o) => o[this.reduce] === this.value);

                    if (!checkDisplay){
                        this.display = '';
                        return;
                    }

                    this.display = checkDisplay;
                    return;

                }

            }

            if (!this.multiselect){
                this.display = this.value;
            }

        },

        selectItem(item){

            if (!this.multiselect){

                document.body.removeEventListener('click', this.close, true);
                this.open = false;
                this.showSearch = false;
            
                this.display = item;

                if (this.reduce){
                    this.$emit('input', item[this.reduce]);
                    return;
                }
                
                this.$emit('input', item);
                return;

            }

            if (!this.label){

                if (typeof this.selectedItems[0] === 'object') this.display = this.selectedItems;
                if (typeof this.selectedItems[0] !== 'object') this.display = this.selectedItems.join(', ');
            }

            if (this.label){

                const bufferSelected = [];

                this.selectedItems.forEach((item) => {
                    bufferSelected.push(item[this.label]);
                });

                this.display = bufferSelected.join(', ');

            }

            if (this.reduce){

                const bufferSelected = [];

                this.selectedItems.forEach((item) => {
                    bufferSelected.push(item[this.reduce]);
                });

                this.$emit('input', bufferSelected);
                return;

            }
            
            this.$emit('input', this.selectedItems);

        },

        addMultiItem(item){

            const find = this.check(item);

            if (!find){
                this.selectedItems.push(item);
                this.selectItem(item);
                return;
            }

            this.selectedItems = this.selectedItems.filter((o) => o !== item);
            this.selectItem(item);

        },

        async openDropdown(el){

            if (this.open) return;

            this.open = true;
            if (this.searchable) this.showSearch = true;

            await this.$nextTick();

            this.dropdownDOM = document.querySelector(`[dropdown-selector="${this.rand}"]`);
            this.setListPossition(el);
            if (this.searchable) this.$refs.search.focus();
            document.body.addEventListener('click', this.close, true);

        },

        setListPossition(el){

            if (!this.dropdownDOM) return;

            const verticalSpace = window.innerHeight - el.getBoundingClientRect().top + el?.offsetHeight;  
            const horizontalSpace = window.innerWidth - el.getBoundingClientRect().right + el?.offsetWidth;     
            const dropdownHeight = this.dropdownDOM?.offsetHeight + 50;
            const dropdownWidth = this.dropdownDOM?.offsetWidth;

            this.dropdownDOM.style.bottom = '';
            this.dropdownDOM.style.top = `${el?.offsetHeight}px`;
            this.dropdownDOM.style.left = '0px';
            this.dropdownDOM.style.right = '';
            
            if (dropdownHeight > verticalSpace || this.setTop){

                this.dropdownDOM.style.top = '';
                this.dropdownDOM.style.bottom = `${el?.offsetHeight}px`;
                this.setToTop = true;

            }

            if (dropdownWidth > horizontalSpace || this.setRight){

                this.dropdownDOM.style.left = '';
                this.dropdownDOM.style.right = `${el?.offsetWidth - (el?.offsetWidth / 2)}px`;

            }

        },

        check(item){

            const find = this.selectedItems.find((o) => o === item);
            if (!find) return false;
            return true;

        },

        clear(){

            this.display = '';

            if (this.multiselect){
                this.selectedItems = [];
                this.$emit('input', []);
                return;
            }
            
            this.$emit('input', '');

        },

        close(e){

            if (this.dropdownDOM){

                const isClickInside = this.dropdownDOM.contains(e.target);
                const checkbox = e.target.classList.contains('v-checkbox');
                const search = e.target.classList.contains('v-search');

                if (!isClickInside && !checkbox && !search){

                    document.body.removeEventListener('click', this.close, true);
                    this.dropdownDOM = null;
                    this.search = '';
                    this.open = false;
                    this.showSearch = false;
                    this.setToTop = false;

                    this.$emit('live', search);

                }

            }

        },

    },
    mounted(){

        this.findModel();

    },
    created(){
        this.rand = this.generateRand(10);
    },

};

</script>

<style lang="scss" src="./vselect.scss"/>
