import { mapState, mapActions } from 'vuex';

export default {

    methods: {

        ...mapActions('global', [
            'setFreeTerms',
        ]),

        app__findFreeTerms(){

            this.app__loaderEnqueue(() => {

                return new Promise(resolve => {

                    this.app__getToken().then(token => {

                        const params = {
                            selected_facility_token: token.accessToken,
                            remote_app: token.remoteApp,
                            evisit: this.visitType === 'evisit',
                            visit_kind: this.visitKind,
                        };

                        if (this.selectedCity && this.selectedCity.name !== 'Dowolne' && this.visitType !== 'evisit') params.city = this.selectedCity.name;
                        if (this.selectedSpeciality) params.specialty = this.selectedSpeciality.name;
                        if (this.selectedDoctor) {
                            params.search_id = this.selectedDoctor.search_id;
                        }
                        if (this.visitDate) params.date = this.visitDate;
                        if (this.app__isMobile()) params.limit = 1;

                        this.$http.get('visits/search_free_terms/', {
                            headers: {
                                'Content-Type': 'Application/json',
                            },
                            params,
                        }).then(response => {

                            this.setFreeTerms([]);

                            let freeTerms = response.data.map(appItem => {
                                const array = appItem.data.map(item => {
                                    const obj = { ...item, app: appItem.app };
                                    return obj;
                                });
                                return array;
                            });

                            freeTerms = freeTerms.flat(1).sort(this.app__termsSort);
                            this.setFreeTerms(freeTerms);
                            if (this.$route.path !== '/visits') this.$router.push('/visits');

                            resolve();

                        }).catch(error => {

                            this.setFreeTerms([]);

                            if (error.response && [429].includes(error.response.status)) {
                                // this.showAlert('error', 'Dzienny limit wyszukań został przekroczony. Spróbuj jutro.');
                                return;
                            }

                            // this.app__handleHttpFailureResponse(error);
                            resolve();
                        });

                    });
                
                });

            });

        },

        app__termsSort(term1, term2) {
            if (!term1.first_free_term) {
                return 1;
            }

            if (!term2.first_free_term) {
                return -1;
            }
            return term1.first_free_term.date < term2.first_free_term.date ? -1 : 1;
        },

    },
    computed:{

        ...mapState('global', [
            'selectedCity',
            'selectedDoctor',
            'selectedSpeciality',
            'visitDate',
            'visitType',
            'visitKind',
        ]),

    },

};
